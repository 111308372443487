export class GPA {
  exgpa: number;
  maxPass: number;
  minPass: number;
  minA?: number;
  minB?: number;
  knownInstitute: boolean;
  reverseGrades: boolean;
  finalGpa?: number;
  constructor(
    exgpa: number,
    maxPass: number,
    minPass: number,
    minB?: number,
    minA?: number,
    reverseGrades = false,
    knownInstitute = false,
    finalGpa = 0
  ) {
    this.exgpa = exgpa;
    this.maxPass = maxPass;
    this.minPass = minPass;
    this.minB = minB;
    this.minA = minA;
    this.reverseGrades = reverseGrades;
    this.knownInstitute = knownInstitute;
    this.finalGpa = finalGpa;
  }
}
