export class RangeValidation {
  name: string;
  valid: boolean;
  duplicate: boolean;
  overlap: boolean;
  constructor(title: string) {
    this.name = title;
    this.valid = true;
    this.duplicate = false;
    this.overlap = false;
  }
}
