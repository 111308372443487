import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavigationFactoryService } from 'src/app/core/services/factory';
import { Institution } from 'src/app/domain/institution';
import { InstitutionAutoCompleteModal } from './institution-autocomplete.modal';
import { EditGradeService } from 'src/app/core/services/edit-grade.service';

@Component({
  selector: 'app-institutions-list',
  templateUrl: './institutions-list.component.html',
  styleUrls: ['./institutions-list.component.scss'],
  providers: [NavigationFactoryService],
})
export class InstitutionsListComponent {
  private _doctoral = true;
  placeholder = 'Search Institution';
  primarySearchField = 'description';

  @Input() set doctoral(studying: boolean) {
    this._doctoral = studying;
  }
  get doctoral() {
    return this._doctoral;
  }

  @Input() selectedInstitution: Institution;
  @Output() knownInstitute = new EventEmitter<boolean>();
  @Output() instituteSelected = new EventEmitter<boolean>();

  constructor(public modal: ModalController, public navFactory: NavigationFactoryService, public editGradeService: EditGradeService) {}

  async openModal(): Promise<void> {
    this.navFactory.getActiveElement();
    const autocompletion = await this.modal.create({
      component: InstitutionAutoCompleteModal,
      componentProps: {
        title: this.placeholder,
        popularList: [],
        searchList: [],
        primarySearchField: this.primarySearchField,
        preselection: this.selectedInstitution ? this.selectedInstitution[this.primarySearchField] : '',
        otherCode: true,
      },
    });

    await autocompletion.present();

    await autocompletion.onDidDismiss().then((r) => {
      this.navFactory.resetActiveElement();
      if (r && r.data && r.data.result) {
        if (!this.selectedInstitution || this.selectedInstitution[this.primarySearchField] != r.data.result[this.primarySearchField]) {
          this.selectedInstitution = r.data.result;
        }
        const known = this.calculateInstitutionKnown(this.selectedInstitution, false);
        this.editGradeService.setSelectedInstitution(this.selectedInstitution);
        this.knownInstitute.emit(known);
        this.instituteSelected.emit(true);
      }
    });
  }
  answerDoctoral(): void {
    const known = this.calculateInstitutionKnown(this.selectedInstitution, !this.doctoral);
    this.knownInstitute.emit(known);
  }

  calculateInstitutionKnown(institution: Institution, doctoral: boolean): boolean {
    if (institution.other) {
      return false;
    }
    if (institution.doctoral_equivalent_institution && institution.sub_doctoral_equivalent_institution) {
      return true;
    }
    let knowInstitute = false;
    if (!institution.doctoral_equivalent_institution && institution.sub_doctoral_equivalent_institution && !doctoral) {
      knowInstitute = true;
    } else if (institution.doctoral_equivalent_institution && !institution.sub_doctoral_equivalent_institution && doctoral) {
      knowInstitute = true;
    }
    return knowInstitute;
  }
}
