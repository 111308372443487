import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalculatorConfig } from 'src/app/core/config';
import { MetaService } from 'src/app/core/services';
import { GPA, Placeholders } from 'src/app/domain';

@Component({
  selector: 'app-numeric-gpa',
  templateUrl: 'numeric-gpa.page.html',
  styleUrls: ['numeric-gpa.page.scss'],
})
export class NumericGpaPage {
  private _numericGpa = true;
  validGrades: boolean;

  gpa: GPA;
  min: number;
  max: number;
  placeholders: Placeholders;

  @Input() set numericGpa(numeric: boolean) {
    this._numericGpa = numeric;
    if (!numeric) {
      this.min = CalculatorConfig.PERCENT_GPA.MIN;
      this.max = CalculatorConfig.PERCENT_GPA.MAX;
      this.placeholders = CalculatorConfig.PERCENT_GPA.PLACEHOLDERS;
    } else {
      this.min = CalculatorConfig.NUMERIC_GPA.MIN;
      this.max = CalculatorConfig.NUMERIC_GPA.MAX;
      this.placeholders = CalculatorConfig.NUMERIC_GPA.PLACEHOLDERS;
    }
    this.gpa = new GPA(null, null, null);
  }
  get numericGpa() {
    return this._numericGpa;
  }
  @Input() editButtonPressed: boolean;
  @Output() showSummary = new EventEmitter<boolean>();
  @Output() gpaGrades = new EventEmitter<GPA>();
  @Output() tertiaryInstitutionEmit = new EventEmitter<string>();

  constructor(private _metaService: MetaService) {
    const metaDescription = `Calculate Numeric grades.`;
    this._metaService.addMetaToPage(metaDescription);
  }

  validateGrade(valid: boolean): void {
    this.validGrades = valid;
    this.emitshowSummary(valid);
  }

  emitshowSummary(show: boolean): void {
    this.showSummary.emit(show);
  }

  selectedGrades(gpa: GPA): void {
    this.gpaGrades.emit(gpa);
  }
}
