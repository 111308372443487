<h2 class="color-primary not-bold">Your tertiary institution</h2>
<div class="d-flex flex-space-between margin-bottom-s">
  <div id="question-inst">What is the tertiary institution you completed your study at?</div>
  <uoa-help id="institution-help" modal-title="What is a tertiary institution?">
    <p>This could be a university, polytechnic, college of education or technical institute.</p>
  </uoa-help>
</div>
<ion-button
  [class.border-light-2]="true"
  fill="clear"
  color="tertiary"
  expand="full"
  class="font-default full-width d-flex flex-space-between margin-0 padding-top-s"
  (click)="openModal()"
  id="open-modal-button"
>
  <span class="d-flex flex-space-between" [class.flex-grow]="!selectedInstitution">
    <span
      class="not-bold color-dark-3-tint"
      *ngIf="!selectedInstitution || !selectedInstitution[primarySearchField]"
      id="placeholder-null"
      >{{ placeholder }}</span
    >
    <span class="not-bold color-dark-1" *ngIf="selectedInstitution && selectedInstitution[primarySearchField]" id="placeholder"
      >{{ selectedInstitution[primarySearchField] }}
    </span>
    <ion-icon name="chevron-down-sharp" id="icon"></ion-icon>
  </span>
</ion-button>

<ng-template
  [ngIf]="
    (selectedInstitution?.doctoral_equivalent_institution && !selectedInstitution?.sub_doctoral_equivalent_institution) ||
    (!selectedInstitution?.doctoral_equivalent_institution && selectedInstitution?.sub_doctoral_equivalent_institution)
  "
>
  <div class="margin-top-l" id="phd-question">Do you plan on studying PhD?</div>

  <div class="toggle-button-cover margin-top-l">
    <div class="button-cover">
      <div class="toggle-button">
        <input type="checkbox" class="checkbox" (change)="answerDoctoral()" [(ngModel)]="doctoral" />
        <div class="knobs">
          <span id="yes">YES</span>
        </div>
        <div class="layer"></div>
      </div>
    </div>
  </div>
</ng-template>
<div class="background-light-1 margin-top-3xl" *ngIf="selectedInstitution">
  <div class="inner-content inner-content-padding-bottom padding-m">
    <p class="padding-top-l not-bold" id="more-info">
      To help us calculate your GPE, we need more information about your tertiary institution's grading details. Refer to
      <a
        id="more-info-link"
        target="_blank"
        href="https://cdn.auckland.ac.nz/assets/auckland/study/applications-and-admissions/gpe-calculator/country-specific-guidelines.pdf"
        >common grading systems for different countries</a
      >
      as a guide.
    </p>
  </div>
</div>
