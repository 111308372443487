import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GradeDetails } from 'src/app/domain';

@Component({
  selector: 'app-add-grade',
  templateUrl: './add-grade.component.html',
  styleUrls: ['./add-grade.component.scss'],
})
export class AddGradeComponent {
  private _gradeList: GradeDetails[];
  private _gradeNames: string[];
  @Input() grade: GradeDetails;
  @Input() set gradeList(grades: GradeDetails[]) {
    this._gradeList = grades;
    this._gradeNames = grades.map((grade) => grade.name.toLowerCase());
  }

  get gradeList() {
    return this._gradeList;
  }

  valid = true;
  alreadyExists = false;
  constructor(private _modalController: ModalController) {}

  close(reset = true): void {
    if (reset) {
      this.grade.name = '';
    }

    this._modalController.dismiss(this.grade);
  }

  updateTimesAchieved(times: number): void {
    this.grade.timesAchieved = times;
  }

  validGradeName(): boolean {
    if (this.grade.name) {
      this.grade.name = this.grade.name.trim();
      this.alreadyExists = this._gradeNames.includes(this.grade.name.toLowerCase());
      return !this.alreadyExists;
    }
    return false;
  }

  addGrade(): void {
    this.valid = this.grade.isValid();
    this.validGradeName();
    if (this.valid && !this.alreadyExists) {
      this.close(false);
    } else {
      this.valid = false;
    }
  }
}
