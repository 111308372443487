<ng-container *ngIf="validRange | async as validRangeObj">
  <div class="d-flex align-center padding-top-l padding-bottom-l">
    <ion-input
      id="from-input"
      type="number"
      [(ngModel)]="grade.rangeFrom"
      class="border-light-2 padding-left"
      [class.border-error]="(!valid && !(grade.validRangeFrom() || grade.markRangeValid())) || !validRangeObj.valid"
      (ionBlur)="validateRange()"
    ></ion-input>

    <span class="padding-s">-</span>

    <ion-input
      id="to-input"
      type="number"
      [(ngModel)]="grade.rangeTo"
      class="border-light-2"
      [class.border-error]="(!valid && !grade.validRangeTo()) || !validRangeObj?.valid"
      (ionBlur)="validateRange()"
    ></ion-input>
  </div>
  <div
    *ngIf="!valid && !(grade.markValid() && grade.markRangeValid() && validRangeObj?.valid)"
    class="color-error margin-top-xs margin-bottom-l d-flex"
  >
    <span *ngIf="showRangeErrorMessage() && !validRangeObj?.valid" class="d-flex align-center padding-right-xs font-l" id="alert-span">
      <ion-icon aria-hidden="true" name="alert-circle-sharp" id="alert-icon"></ion-icon>
    </span>
    <span
      *ngIf="!grade.markRangeValid(); else otherError"
      aria-label="Minimum value must be smaller than maximum value. E.g. 80 to 100."
      id="min-val-bigger-than-max"
      >Minimum value must be smaller than maximum value. E.g. 80-100.</span
    >
    <ng-template #otherError>
      <span *ngIf="!grade.markValid(); else rangeError" aria-label="Please enter a number between 0 to 120." id="num-between-0-120"
        >Please enter a number between 0-120.</span
      >
    </ng-template>
    <ng-template #rangeError>
      <span
        *ngIf="validRangeObj.duplicate && showRangeErrorMessage(); else overlapError"
        aria-label="Can not have duplicate range."
        id="duplicate-range"
        >Can not have duplicate range.</span
      >
      <ng-template #overlapError>
        <span *ngIf="validRangeObj.overlap && showRangeErrorMessage()" aria-label="Ranges can not overlap each other." id="range-overlap"
          >Ranges can not overlap each other.</span
        >
      </ng-template>
    </ng-template>
  </div>
</ng-container>
