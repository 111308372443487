import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-field',
  templateUrl: 'input-field.component.html',
  styleUrls: ['input-field.component.scss'],
})
export class InputFieldComponent {
  @Input() gpa: number;
  @Input() placeholder: string;
  @Input() numeric = true;
  @Input() textCenter = true;
  @Output() selectedValue = new EventEmitter<number>();

  valueSelected(gpa) {
    if (this.gpa) {
      this.gpa = parseFloat(this.gpa.toFixed(4));
    }
    this.selectedValue.emit(this.gpa);
  }
}
