<app-numeric-grades
  *ngIf="!validGrades"
  [editButtonPressed]="editButtonPressed"
  [min]="min"
  [max]="max"
  [numericGpa]="numericGpa"
  [placeholders]="placeholders"
  [gpa]="gpa"
  (validGrades)="validateGrade($event)"
  (gpaGrades)="selectedGrades($event)"
  (tertiaryInstitution)="tertiaryInstitution($event)"
></app-numeric-grades>
