import { Component, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NumericCalculatorService } from './core/services';
import { GPA, GradeDetails, GradeType } from './domain';
import { environment } from 'src/environments/environment';
import { EditGradeService } from './core/services/edit-grade.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  gpaType: string;
  calculateSummary = false;
  readonly gradeTypes = GradeType;
  gpa: GPA;
  editButtonPressed: boolean;
  constructor(
    private _calculator: NumericCalculatorService,
    private _gtmService: GoogleTagManagerService,
    public editGradeService: EditGradeService
  ) {}

  ngOnInit(): void {
    if (environment.googleTagId) {
      this._gtmService.addGtmToDom();
    }
  }

  setType(type: string): void {
    if (environment.googleTagId) {
      const gtmTag = {
        event: 'gpe_type',
        type: type,
      };
      this._gtmService.pushTag(gtmTag);
    }
    this.editGradeService.reset();
    this.gpaType = null;
    this.editGradeService.setGpaType(type);
    setTimeout(() => {
      this.gpaType = this.editGradeService.gpaType;
    }, 150);
    this.gpa = this.editGradeService.gpaGrades;
    this.editButtonPressed = this.editGradeService.editButtonPressed;
  }

  showSummary(show: boolean): void {
    this.calculateSummary = show;
  }

  resetTypeField(): void {
    this.calculateSummary = false;
    this.editGradeService.reset();
    this.gpaType = this.editGradeService.gpaType;
    this.editButtonPressed = this.editGradeService.editButtonPressed;
  }

  editGrades(): void {
    this.calculateSummary = false;
    this.editGradeService.setEditButtonPressed(true);
    this.editButtonPressed = this.editGradeService.editButtonPressed;
  }

  selectedGrades(gpa: GPA): void {
    this.gpa = gpa;
    this.gpa.finalGpa = this._calculator.calculate(
      gpa.exgpa,
      gpa.minPass,
      gpa.maxPass,
      gpa.minA ? gpa.minA : 0,
      gpa.minB ? gpa.minB : 0,
      gpa.knownInstitute
    );
    this.editGradeService.setGpaGrades(gpa);
  }
}
