import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { GPA, GradeDetails, GradeType } from 'src/app/domain';
import { EditGradeService } from 'src/app/core/services/edit-grade.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, AfterViewInit {
  constructor(public toastController: ToastController, public editGradeService: EditGradeService) {}

  @ViewChild('title') title: ElementRef<HTMLElement>;

  @Output() reset = new EventEmitter<boolean>();
  @Output() editButtonPressed = new EventEmitter<boolean>();

  gpaType: GradeType;

  gradeTypes = GradeType;
  gpa: GPA;
  achievedGrades: GradeDetails[];
  finalGradesList: GradeDetails[];
  minPass: string;
  minA: string;
  minB: string;
  tertiaryInstitutionName: string;
  toggle: boolean = false;

  ngOnInit() {
    if (this.editGradeService.enteredGradeList) {
      this.finalGradesList = this.editGradeService.enteredGradeList;
      this.achievedGrades = this.editGradeService.enteredGradeList.filter((grade) => {
        return grade.timesAchieved > 0;
      });
    }
    this.gpa = this.editGradeService.gpaGrades;

    this.gpaType = this.editGradeService.gpaType as GradeType;
    this.tertiaryInstitutionName = this.editGradeService.selectedInstitution.description;
    this.minPass = this.editGradeService.minimumPass;
    this.minA = this.editGradeService.minimumA;
    this.minB = this.editGradeService.minimumB;
  }
  ngAfterViewInit(): void {
    this.title.nativeElement.focus();
  }

  recalculate(): void {
    this.reset.emit(true);
  }

  edit(): void {
    this.editButtonPressed.emit(true);
  }

  copyGpa() {
    navigator.clipboard.writeText(this.gpa.finalGpa.toString());
    this.copiedGpaToast();
  }

  async copiedGpaToast() {
    const toast = await this.toastController.create({
      message: 'Final GPE value has been copied',
      duration: 2000,
      position: 'middle',
      color: 'primary',
    });
    toast.present();
  }

  changeToggle() {
    this.toggle = !this.toggle;
  }
}
