<div class="margin-bottom-s" id="select-type">Select your grade type.</div>
<ion-item class="border-light-2" lines="none" [class.margin-bottom-4xl]="!type">
  <ion-label hidden>Select your grade type.</ion-label>
  <ion-select
    class="full-width font-default padding-left-none"
    [(ngModel)]="type"
    (ionChange)="onTypeChange($event.detail.value)"
    [selectedText]="!type ? 'Select' : ''"
  >
    <ion-select-option [value]="gradeTypes.NUMERIC">GPA</ion-select-option>
    <ion-select-option [value]="gradeTypes.PERCENT">Percentage</ion-select-option>
    <ion-select-option [value]="gradeTypes.ABC">Letter Grades - ABC</ion-select-option>
    <ion-select-option [value]="gradeTypes.HONOURS">Descriptive Grades - Honours</ion-select-option>
    <ion-select-option [value]="gradeTypes.DISTINCTION">Descriptive Grades - Distinction</ion-select-option>
    <ion-select-option [value]="gradeTypes.EXCELLENCE">Descriptive Grades - Excellence</ion-select-option>
    <ion-select-option [value]="gradeTypes.OTHER">Other</ion-select-option>
  </ion-select>
</ion-item>
