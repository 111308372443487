<div class="d-flex padding-top-l padding-bottom-l">
  <ion-button fill="clear" size="small" class="border-light-2" expand="full" color="dark" (click)="onDecrement()" id="decrement-button">
    <ion-icon slot="icon-only" name="remove-sharp" id="decrement-icon"></ion-icon>
  </ion-button>
  <ion-input
    type="number"
    class="border-light-2"
    [(ngModel)]="grade.timesAchieved"
    (ionBlur)="valueChanged()"
    max="99"
    min="0"
    id="no-of-times-achieved-input"
  ></ion-input>
  <ion-button expand="full" size="small" (click)="onIncrement()" id="increment-button">
    <ion-icon slot="icon-only" name="add-sharp" id="increment-icon"></ion-icon>
  </ion-button>
</div>
<div *ngIf="!valid && !grade.timesAchievedRangeValid()" class="color-error margin-top-xs margin-bottom-l d-flex" id="s">
  <span class="d-flex align-center padding-right-xs font-l">
    <ion-icon aria-hidden="true" name="alert-circle-sharp" id="error-icon"></ion-icon>
  </span>
  <span aria-label="Please enter a number between 0 to 99." id="error-message">Please enter a number between 0-99.</span>
</div>
