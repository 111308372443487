import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { NumericGpaPage } from './numeric-gpa.page';
import { NumericGradesModule, NumericSummaryModule } from 'src/app/shared/components';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, FormsModule, NumericGradesModule, NumericSummaryModule],
  declarations: [NumericGpaPage],
  exports: [NumericGpaPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NumericGpaPageModule {}
