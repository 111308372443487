import { NgModule } from '@angular/core';
import { GpaTypeComponent } from './gpa-type.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

const COMPONENT = [GpaTypeComponent];

@NgModule({
  declarations: [COMPONENT],
  exports: [COMPONENT],
  imports: [IonicModule, CommonModule, RouterModule, FormsModule],
})
export class GpaTypeModule {}
