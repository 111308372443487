import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { NonNumericPage } from './non-numeric.page';

import { GradeComponent } from './grade/grade.component';
import { GradeTimesReceivedComponent } from './grade-times-received/grade-times-received.component';
import { AddGradeComponent } from './add-grade/add-grade.component';
import { GradeRangeComponent } from './grade-range/grade-range.component';
import { InstitutionsListModule, NumericSummaryModule } from 'src/app/shared';
import { DropdownFieldComponent } from './dropdown-field/dropdown-field.component';

const COMPONENT = [GradeComponent, GradeTimesReceivedComponent, AddGradeComponent, GradeRangeComponent, DropdownFieldComponent];

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, InstitutionsListModule, NumericSummaryModule],
  declarations: [NonNumericPage, ...COMPONENT],
  exports: [NonNumericPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NonNumericPageModule {}
