import { Injectable } from '@angular/core';
import { GPA, GradeDetails } from 'src/app/domain';
import { Institution } from 'src/app/domain/institution';

@Injectable({
  providedIn: 'root',
})
export class EditGradeService {
  selectedInstitution: Institution;
  gpaType: string;
  enteredGradeList: GradeDetails[];
  editButtonPressed: boolean;
  minimumPass: string;
  minimumA: string;
  minimumB: string;
  gpaGrades: GPA;
  exgpa: number;
  maxPass: number;
  minPass: number;
  minB: number;
  minA: number;
  knownInstitute: boolean;

  setSelectedInstitution(institute: Institution): void {
    this.selectedInstitution = institute;
  }

  setGpaType(gpaType: string): void {
    this.gpaType = gpaType;
  }

  setEnteredGradeList(gradeList: GradeDetails[]): void {
    this.enteredGradeList = gradeList;
  }

  setEditButtonPressed(pressed: boolean): void {
    this.editButtonPressed = pressed;
  }

  setMinimumPass(minPass: string): void {
    this.minimumPass = minPass;
  }

  setMinimumA(minA: string): void {
    this.minimumA = minA;
  }

  setMinimumB(minB: string): void {
    this.minimumB = minB;
  }

  setGpaGrades(gpa: GPA): void {
    this.gpaGrades = gpa;
  }

  setExgpa(exgpa: number): void {
    this.exgpa = exgpa;
  }

  setMaxPass(maxPass: number): void {
    this.maxPass = maxPass;
  }

  setMinPass(minPass: number): void {
    this.minPass = minPass;
  }

  setMinB(minB: number): void {
    this.minB = minB;
  }

  setMinA(minA: number): void {
    this.minA = minA;
  }

  setKnownInstitute(known: boolean): void {
    this.knownInstitute = known;
  }
  reset(): void {
    this.selectedInstitution = undefined;
    this.gpaGrades = undefined;
    this.gpaType = null;
    this.enteredGradeList = [];
    this.editButtonPressed = false;
    this.minimumPass = null;
    this.minimumA = null;
    this.minimumB = null;
    this.exgpa = null;
    this.maxPass = null;
    this.minPass = null;
    this.minB = null;
    this.minA = null;
    this.knownInstitute = false;
  }
}
