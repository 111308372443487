import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GradeDetails } from 'src/app/domain';

@Component({
  selector: 'app-dropdown-field',
  templateUrl: 'dropdown-field.component.html',
  styleUrls: ['dropdown-field.component.scss'],
})
export class DropdownFieldComponent {
  @Input() selectedGrade: string;
  @Input() gradeList: GradeDetails[];
  @Input() dropdownTitle = 'Select your grade.';
  @Output() selectedValue = new EventEmitter<string>();

  constructor() {}

  valueSelected(name): void {
    this.selectedValue.emit(name);
  }
}
