import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, sample } from 'rxjs/operators';
import { NonNumericCalculatorService } from 'src/app/core/services/non-numeric-calculator.service';
import { NonNumericValidationService } from 'src/app/core/services/non-numeric-validation.service';
import { GradeDetails, RangeValidation } from 'src/app/domain';

@Component({
  selector: 'app-grade-range',
  templateUrl: './grade-range.component.html',
  styleUrls: ['./grade-range.component.scss'],
})
export class GradeRangeComponent implements OnInit {
  @Input() grade: GradeDetails;
  @Input() gradeList: GradeDetails[];
  @Input() valid = true;
  @Input() newGrade = false;

  validRanges = true;
  validRange: Observable<any>;
  constructor(public validationService: NonNumericValidationService, public nonNumericService: NonNumericCalculatorService) {}

  ngOnInit(): void {
    this.validRange = this.nonNumericService.rangesList$.pipe(
      map((list) => {
        if (this.grade.name == '') {
          return new RangeValidation('');
        }
        return list[this.grade.name];
      })
    );
  }

  validateRange() {
    if (!this.newGrade) {
      this.validRanges = this.validationService.validateRanges(this.gradeList);
    }
  }
  showRangeErrorMessage() {
    return this.validationService.lastRangeErrorCode == this.grade.name;
  }
}
