import { Component, EventEmitter, Output } from '@angular/core';
import { GradeType } from 'src/app/domain';
import { EditGradeService } from 'src/app/core/services/edit-grade.service';

@Component({
  selector: 'app-gpa-type',
  templateUrl: 'gpa-type.component.html',
  styleUrls: ['gpa-type.component.scss'],
})
export class GpaTypeComponent {
  constructor(public editGradeService: EditGradeService) {
    let gpaTypeFromService = this.editGradeService.gpaType;
    if (gpaTypeFromService) {
      this.type = gpaTypeFromService;
    }
  }
  type: string;
  readonly gradeTypes = GradeType;
  @Output() gpaType = new EventEmitter<string>();

  onTypeChange(type: string) {
    this.gpaType.emit(type);
    this.editGradeService.setGpaType(type);
  }
}
