export class GradeDetails {
  name: string;
  timesAchieved: number;
  rangeFrom: number;
  rangeTo: number;
  constructor(title: string, timesAchieved: number = 0, maxRange: number = 0, minRange: number = 0) {
    this.name = title;
    this.timesAchieved = timesAchieved;
    this.rangeTo = maxRange;
    this.rangeFrom = minRange;
  }

  isValid(): boolean {
    return null != this.name.trim() && this.markValid() && this.markRangeValid() && this.timesAchievedRangeValid();
  }

  markValid(): boolean {
    return this.validRangeFrom() && this.validRangeTo();
  }

  validRangeFrom(): boolean {
    return this.rangeFrom >= 0 && this.rangeFrom <= 120;
  }

  validRangeTo(): boolean {
    return this.rangeTo >= 0 && this.rangeTo <= 120;
  }

  markRangeValid(): boolean {
    return this.rangeFrom < this.rangeTo;
  }

  timesAchievedRangeValid(): boolean {
    return this.timesAchieved >= 0 && this.timesAchieved <= 99;
  }
}
