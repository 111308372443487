import { Component, Input, Output, EventEmitter } from '@angular/core';

import { GradeDetails, GradeType } from 'src/app/domain';

@Component({
  selector: 'app-grade',
  templateUrl: './grade.component.html',
  styleUrls: ['./grade.component.scss'],
})
export class GradeComponent {
  @Input() grade: GradeDetails;
  @Input() mobile = false;
  @Input() currentStep: number;
  @Input() gradeType: GradeType;
  @Input() gradeList: GradeDetails[];
  @Input() validGrades: boolean;
  @Output() deleteGrade = new EventEmitter();
  type = GradeType;

  deleteRow(): void {
    this.deleteGrade.emit(this.grade.name);
  }
  selectedTimesAchieved(timesAchieved: number): void {
    this.grade.timesAchieved = timesAchieved;
  }
}
