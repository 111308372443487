import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { NumericCalculatorService } from 'src/app/core/services';

import { NonNumericCalculatorService } from 'src/app/core/services/non-numeric-calculator.service';
import { NonNumericValidationService } from 'src/app/core/services/non-numeric-validation.service';
import { GPA, GradeDetails, GradeType } from 'src/app/domain';
import { Institution } from 'src/app/domain/institution';
import { AddGradeComponent } from './add-grade/add-grade.component';
import { EditGradeService } from 'src/app/core/services/edit-grade.service';

@Component({
  selector: 'app-non-numeric',
  templateUrl: './non-numeric.page.html',
  styleUrls: ['./non-numeric.page.scss'],
})
export class NonNumericPage implements OnInit {
  @ViewChild('title') title: ElementRef<HTMLElement>;

  private _titleText = `For your most recent tertiary qualification,  `;
  private _gradetype: GradeType;
  mobile: boolean = false;
  currentStep: number = 1;
  institutionSelected: boolean;
  percentageText = 'the <strong>percentage range (%)</strong> for each grade';
  timesText = 'the <strong>number of times you achieved</strong> each grade';
  titleText: string;
  gradeList: GradeDetails[] = [];
  answerMore: boolean;
  minA: GradeDetails;
  minB: GradeDetails;
  minPass: GradeDetails;
  knownInstitute = false;
  validGrades = true;
  selectedInstitution: string;
  previouslySelectedInstitution: Institution;
  previouslyEnteredMinPass: string;

  @Input() set gradeType(type: GradeType) {
    this._gradetype = type;
    this.gradeList = this.nonNumericService.getGradesList(type);
    this.currentStep = 1;
    this.setDefaultValue();
  }
  get gradeType() {
    return this._gradetype;
  }
  @Input() editButtonPressed: boolean;
  @Output() showSummary = new EventEmitter<boolean>();
  @Output() gpaGrades = new EventEmitter<GPA>();

  constructor(
    private _platform: Platform,
    private _modalController: ModalController,
    public nonNumericService: NonNumericCalculatorService,
    public validationService: NonNumericValidationService,
    private _numericService: NumericCalculatorService,
    public editGradeService: EditGradeService
  ) {
    this.mobile = this._platform.is('mobile');
    this.updateTitle();
  }

  ngOnInit(): void {
    this.setDefaultValue();
    this.markInstituteSelected(!!this.editGradeService.selectedInstitution);

    if (this.editButtonPressed) {
      this.setExistingValues();
    }
  }

  setExistingValues(): void {
    this.gradeList = this.editGradeService.enteredGradeList;
    if (this.gradeList.length == 0) {
      this.gradeList = this.nonNumericService.getGradesList(this.gradeType);
    } else {
      this.nonNumericService.updateGradesList(this.gradeList);
    }
    this.minPass = this.gradeList.find((grade) => grade.name === this.editGradeService.minimumPass);
    this.knownInstitute = this.editGradeService.knownInstitute;

    if (this.mobile) {
      this.currentStep = 2;
    }
    if (this.editGradeService.minimumB || this.editGradeService.minimumA) {
      this.minB = this.gradeList.find((grade) => grade.name === this.editGradeService.minimumB);
      this.minA = this.gradeList.find((grade) => grade.name === this.editGradeService.minimumA);
      this.answerMore = true;
    }
  }

  gotoStep(step: number): void {
    this.currentStep = step;
    this.updateTitle();
    this.title.nativeElement.focus();
  }

  updateTitle() {
    if (this.mobile) {
      this.titleText = `${this._titleText} enter ${this.timesText}.`;
      if (this.currentStep == 2) {
        this.titleText = `Edit ${this.percentageText}. Refer to your tertiary institution’s grading system.`;
      }
    } else {
      this.titleText = `${this._titleText} enter ${this.timesText} and edit ${this.percentageText}.`;
    }
  }

  async addGrade() {
    const modal = await this._modalController.create({
      component: AddGradeComponent,
      cssClass: 'addGradeModal',
      componentProps: {
        grade: new GradeDetails(''),
        gradeList: this.gradeList,
      },
    });
    await modal.present();
    await modal.onDidDismiss().then((r) => {
      if (r.data.name) {
        this.gradeList.push(r.data);
        this.sortGrades();
      }
    });
  }

  sortGrades(): void {
    this.gradeList = this.gradeList.sort((a, b) => b.rangeTo - a.rangeTo);
    this.validationService.validateRanges(this.gradeList);
  }

  getInstitution(institution: boolean): void {
    this.knownInstitute = institution;
    this.editGradeService.setKnownInstitute(institution);
    this.sortGrades();
  }

  markInstituteSelected(selected: boolean) {
    this.institutionSelected = selected;
    this.previouslySelectedInstitution = this.editGradeService.selectedInstitution;
  }

  updateValue(value: string, field: string) {
    switch (field) {
      case 'minA':
        this.editGradeService.setMinimumA(value);
        return (this.minA = this.gradeList.find((grade) => grade.name === value));
      case 'minB':
        this.editGradeService.setMinimumB(value);
        return (this.minB = this.gradeList.find((grade) => grade.name === value));
      case 'minPass':
        this.editGradeService.setMinimumPass(value);
        return (this.minPass = this.gradeList.find((grade) => grade.name === value));
    }
  }

  setDefaultValue() {
    this.minA = null;
    this.minB = null;
    this.minPass = null;
  }

  deleteRow(name: string): void {
    this.gradeList.splice(
      this.gradeList.findIndex((grade) => grade.name === name),
      1
    );
    this.nonNumericService.resetRangeList(this.gradeList);
    this.validationService.validateRanges(this.gradeList);
  }

  async validateGpa() {
    this.validGrades =
      this.validationService.ValidGradeDetails(this.gradeList) && this.validMinPass() && this.validMinA() && this.validMinB();
    if (this.validGrades) {
      let gpa = this.nonNumericService.getNumericGrades(
        this.gradeList,
        this.minPass ? this.minPass.name : '',
        this.minA ? this.minA.name : '',
        this.minB ? this.minB.name : ''
      );
      gpa.knownInstitute = this.knownInstitute;
      this.selectedGrades(gpa);
      this.emitShowSummary(this.validGrades);
      this.editGradeService.setEnteredGradeList(this.gradeList);
    } else {
      await this._numericService.showErrorToast();
    }
  }

  answerMoreQuestions() {
    if (!this.answerMore) {
      this.minA = null;
      this.minB = null;
      this.editGradeService.setMinimumA('');
      this.editGradeService.setMinimumB('');
    }
  }

  emitShowSummary(show: boolean): void {
    this.showSummary.emit(show);
  }

  selectedGrades(gpa: GPA): void {
    this.gpaGrades.emit(gpa);
  }

  validMinPass() {
    return !this.checkNull(this.minPass);
  }

  validMinB(): boolean {
    return this.checkNull(this.minB) || (!this.checkNull(this.minB) && this.minPass.rangeFrom < this.minB.rangeFrom);
  }

  validMinA(): boolean {
    return (
      this.checkNull(this.minA) ||
      (!this.checkNull(this.minA) && this.minPass.rangeFrom < this.minA.rangeFrom && this.minA.rangeFrom > this.minB.rangeFrom)
    );
  }

  checkNull(grade): boolean {
    return ['', null].indexOf(grade) != -1;
  }
}
