import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GradeDetails } from 'src/app/domain';

@Component({
  selector: 'app-grade-times-received',
  templateUrl: './grade-times-received.component.html',
  styleUrls: ['./grade-times-received.component.scss'],
})
export class GradeTimesReceivedComponent {
  @Input() grade: GradeDetails;
  @Input() valid = true;
  @Output() selectedTimesAchieved = new EventEmitter<number>();

  onIncrement(): void {
    if (this.grade.timesAchieved < 99) {
      this.grade.timesAchieved++;
      this.emitTimesAchieved();
    }
  }
  onDecrement(): void {
    if (this.grade.timesAchieved > 0) {
      this.grade.timesAchieved--;
      this.emitTimesAchieved();
    }
  }

  emitTimesAchieved(): void {
    this.selectedTimesAchieved.emit(this.grade.timesAchieved);
  }

  valueChanged() {
    this.grade.timesAchieved = parseInt(this.grade.timesAchieved.toString().substring(0, 2));
  }
}
