<ion-item
  class="d-flex align-item-center border-light-2 input-wrapper margin-top-m"
  lines="none"
  [class.change-padding]="!numeric && gpa != null"
>
  <ion-input
    inputmode="decimal"
    type="number"
    [placeholder]="placeholder"
    (ionBlur)="valueSelected(gpa)"
    [(ngModel)]="gpa"
    autocomplete="off"
    enterkeyhint="next"
    step=".01"
    debounce="400"
  ></ion-input>
  <span *ngIf="!numeric && gpa != null">%</span>
</ion-item>
