import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NumericCalculatorService } from 'src/app/core/services';
import { GpaValidationService } from 'src/app/core/services/gpa-validation.service';
import { GPA, Placeholders } from 'src/app/domain';
import { EditGradeService } from 'src/app/core/services/edit-grade.service';
import { Institution } from 'src/app/domain/institution';

@Component({
  selector: 'app-numeric-grades',
  templateUrl: 'numeric-grades.component.html',
  styleUrls: ['numeric-grades.component.scss'],
})
export class NumericGradesComponent {
  gpaType = 'GPA';
  valid = true;
  answerMore = false;
  reverseGrades = false;
  institutionSelected = false;
  private _numericGpa = true;
  selectedInstitution: string;
  previouslySelectedInstitution: Institution;

  @Input() set numericGpa(numeric: boolean) {
    this._numericGpa = numeric;
    if (!numeric) {
      this.gpaType = 'percentage';
    } else {
      this.gpaType = 'GPA';
    }
  }
  get numericGpa() {
    return this._numericGpa;
  }

  @Input() editButtonPressed: boolean;
  @Input() gpa = new GPA(null, null, null);
  @Input() min: number;
  @Input() max: number;
  @Input() placeholders: Placeholders;

  @Output() validGrades = new EventEmitter<boolean>();
  @Output() gpaGrades = new EventEmitter<GPA>();

  validExgpa = () => {
    return this._validationService.validExGpa(this.gpa, this.min, this.max);
  };
  validMaxPass = () => {
    return this._validationService.validMaxPass(this.gpa, this.min, this.max);
  };
  validMinPass = () => {
    return this._validationService.validMinPass(this.gpa, this.min, this.max);
  };
  validMinB = () => {
    return this._validationService.validMinB(this.gpa, this.min, this.max);
  };
  validMinA = () => {
    return this._validationService.validMinA(this.gpa, this.min, this.max);
  };

  constructor(
    private _validationService: GpaValidationService,
    private _numericService: NumericCalculatorService,
    public editGradeService: EditGradeService
  ) {}

  ngOnInit(): void {
    this.markInstituteSelected(!!this.editGradeService.selectedInstitution);
    this.previouslySelectedInstitution = this.editGradeService.selectedInstitution;
    if (this.editButtonPressed) {
      this.setExistingValues();
    }
  }

  setExistingValues() {
    this.gpa.exgpa = this.editGradeService.exgpa;
    this.gpa.maxPass = this.editGradeService.maxPass;
    this.gpa.minPass = this.editGradeService.minPass;
    this.gpa.knownInstitute = this.editGradeService.knownInstitute;
    if (this.editGradeService.minA || this.editGradeService.minB) {
      this.gpa.minA = this.editGradeService.minA;
      this.gpa.minB = this.editGradeService.minB;
      this.answerMore = true;
    }
  }

  answerMoreQuestions() {
    if (!this.answerMore) {
      this.gpa.minA = null;
      this.gpa.minB = null;
      this.editGradeService.setMinA(this.gpa.minA);
      this.editGradeService.setMinB(this.gpa.minB);
    }
  }

  async validateGPA(): Promise<void> {
    this.reversedGrades();
    if (!this.validExgpa() || !this.validMaxPass() || !this.validMinPass() || !this.validMinB() || !this.validMinA()) {
      this.valid = false;
      await this._numericService.showErrorToast();
    } else {
      this.valid = true;
      this.gpaGrades.emit(this.gpa);
      this.validGrades.emit(this.valid);
    }
  }

  updateValue(value: number, field: string) {
    this.gpa[field] = value;
    this.reversedGrades();
    switch (field) {
      case 'exgpa':
        this.editGradeService.setExgpa(value);
        break;
      case 'maxPass':
        this.editGradeService.setMaxPass(value);
        break;
      case 'minPass':
        this.editGradeService.setMinPass(value);
        break;
      case 'minB':
        this.editGradeService.setMinB(value);
        break;
      case 'minA':
        this.editGradeService.setMinA(value);
        break;
    }
  }
  reversedGrades(): void {
    this.gpa.reverseGrades = this.gpa.minPass > this.gpa.maxPass ? true : false;
  }

  getInstitution(institution: boolean): void {
    this.gpa.knownInstitute = institution;
    this.editGradeService.setKnownInstitute(institution);
  }

  markInstituteSelected(selected: boolean) {
    this.institutionSelected = selected;
  }
}
