import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IonSearchbar, ModalController, NavParams } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { InstitutionService } from 'src/app/core/services/institution.service';

import { CodeDesc } from '../../../domain/code-desc';

@UntilDestroy()
@Component({
  selector: 'institution-autocomplete-modal',
  templateUrl: 'institution-autocomplete.modal.html',
  styleUrls: ['./institution-autocomplete.modal.scss'],
})
export class InstitutionAutoCompleteModal implements OnInit {
  @ViewChild('autocompletesearchbar') searchbar: IonSearchbar;
  readonly ITEMS_PER_PAGE: number = 10;

  title: string;
  searchList: any[];
  shownResultsList: CodeDesc[] = [];

  resultList: any[];
  searchFieldList: string[];
  searchString: string = '';
  wasSearchStringPresent: boolean = false; // indicates current presence of searchString for future condition check.
  otherCode: string;
  page: number = 0;
  maxChars: number;

  cursorIdx: number = null;
  searchControl = new UntypedFormControl();
  filteredText: string;
  institutionsList;
  loading = new Subject<boolean>();

  constructor(public navParams: NavParams, public viewCtrl: ModalController, private _instituteService: InstitutionService) {
    this.title = navParams.get('title');

    this.searchList = navParams.get('searchList');
    this.resultList = this.searchList ? this.searchList : [];
    let primarySearchField: string = navParams.get('primarySearchField'),
      secondarySearchField: string = navParams.get('secondarySearchField');
    this.searchFieldList = [];
    if (primarySearchField) {
      this.searchFieldList.push(primarySearchField);
    }
    if (secondarySearchField) {
      this.searchFieldList.push(secondarySearchField);
    }
    this.searchString = navParams.get('preselection');
    this.otherCode = navParams.get('otherCode');

    this.maxChars = navParams.get('maxChars');
    if (!this.maxChars) {
      this.maxChars = 50;
    }
  }
  ngOnInit(): void {
    const $list = this.searchControl.valueChanges.pipe(
      tap((a) => (this.searchString = a)),
      debounceTime(800),
      distinctUntilChanged(),
      map((text) =>
        text
          .split(' ')
          .filter((li) => !['of', 'university', 'other', 'institution', 'tertiary'].find((element) => element.includes(li.toLowerCase())))
          .join(' ')
          .trim()
      ),
      filter((text) => {
        if (text.length > 2 && text !== this.filteredText) {
          this.loading.next(true);
          this.filteredText = text;
          return true;
        }
        if (text !== this.filteredText) {
          this.searchList = [];
        }
        this.filteredText = text;
        this.autocomplete(this.searchString);
        return false;
      }),
      untilDestroyed(this),
      switchMap((search) => this._instituteService.searchInstitute(search)),
      tap((_) => this.loading.next(false))
    );
    this.institutionsList = $list.subscribe((res) => {
      this.searchList = res;
      this.autocomplete(this.searchString);
    });

    if (this.searchString && this.searchString.length > 0) {
      this.searchControl.setValue(this.searchString);
    }
  }

  ionViewDidEnter() {
    this.setFocusToSearchBar();
  }

  hasNextPage(): boolean {
    return this.resultList.length > (this.page + 1) * 10;
  }

  getSearchField(result) {
    return result['' + this.searchFieldList[0]]; // The first/primary primarySearchField.
  }

  autocomplete(event: any) {
    let val: string = event; // event.target.value;
    // To get around event bubbling on IE, page is set to 'zero (0)' only when searchString is/was
    // not empty immediately prior to this check.
    if (this.searchString.length || this.wasSearchStringPresent) {
      // implicit: no search string now;
      this.page = 0;
      this.updateSearchStringPresence(); // set to false; if searchString is 0
    }
    this.searchString = val;
    this.cursorIdx = null;
    this.findResults(val);
    this.refreshPaginatedLists();
  }

  findResults(searchString: string): void {
    if (searchString && searchString.trim().length > 0) {
      let searchStringWords: string[] = searchString.trim().toLowerCase().split(' ');
      this.resultList = this.searchList.filter((q) => {
        let isFound: boolean = true;
        for (let searchField of this.searchFieldList) {
          for (let i = 0; i < searchStringWords.length; i++) {
            isFound = q[searchField].toLowerCase().indexOf(searchStringWords[i]) !== -1;
            if (!isFound) {
              // Stop if the current searchField is not a complete match for the search string words.
              break;
            }
          }
          if (isFound) {
            // Stop if found match on a searchField
            break;
          }
        }
        return isFound;
      });
      this.resultList.sort((a, b) => {
        let toReturn: number = 0;
        if (this.searchFieldList.length !== 0) {
          for (let searchField of this.searchFieldList) {
            toReturn = this.compareAndRankByField(a, b, searchField, searchString);
            if (toReturn !== 0) {
              break;
            }
          }
        }
        return toReturn;
      });
    } else {
      this.resultList = this.searchList;
    }
  }

  private compareAndRankByField(a: object, b: object, searchField: string, searchString: string): number {
    const aSearchFieldValue: string = a[searchField].toLowerCase(),
      bSearchFieldValue: string = b[searchField].toLowerCase(),
      aWords: string[] = aSearchFieldValue.split(' '),
      bWords: string[] = bSearchFieldValue.split(' ');
    let toReturn: number = aWords.length - bWords.length; // 1. by no. of words
    if (toReturn === 0) {
      // 2. by position of the entire search string.
      toReturn = aSearchFieldValue.indexOf(searchString) - bSearchFieldValue.indexOf(searchString);
    }
    if (toReturn === 0) {
      // 3. Find the position of search-string Words in the field string words.
      const searchWords: string[] = searchString.split(' ');
      for (const word of searchWords) {
        toReturn = this.getIndexForSubString(aWords, word) - this.getIndexForSubString(bWords, word);
        if (toReturn !== 0) {
          break;
        }
      }
    }
    if (toReturn === 0) {
      // 4. Alphabetically
      toReturn = aSearchFieldValue.localeCompare(bSearchFieldValue);
    }
    return toReturn;
  }

  private getIndexForSubString(words: string[], substring: string): number {
    for (let index = 0; index < words.length; ++index) {
      if (words[index].includes(substring)) {
        return index;
      }
    }
    return null;
  }

  handleKeys(event) {
    if (event.keyCode == 40) {
      //arrow down
      if (this.cursorIdx == null || this.cursorIdx == 9 || this.cursorIdx == this.resultList.length - 1 - this.page * 10) {
        this.cursorIdx = 0;
      } else {
        this.cursorIdx++;
      }
    } else if (event.keyCode == 38 && this.cursorIdx != null) {
      //arrow up
      if (this.cursorIdx == 0) {
        this.cursorIdx = this.resultList.length - this.page * 10 < 10 ? this.resultList.length - 1 - this.page * 10 : 9;
      } else {
        this.cursorIdx--;
      }
    } else if (event.keyCode == 13 && this.cursorIdx != null) {
      // enter
      let result = this.resultList[this.cursorIdx + this.page * 10];
      this.select(result);
    }
  }

  select(result) {
    this.viewCtrl.dismiss({ result: result });
  }

  gotoPrevPage(): void {
    this.page--;
    this.refreshPaginatedLists();
  }

  gotoNextPage(): void {
    this.page++;
    this.refreshPaginatedLists();
  }

  private refreshPaginatedLists(): void {
    // If popular items need to be sorted as well then the sortedPopularItems should take the place if popularItems here
    let pageItemStartIndex: number = this.page * this.ITEMS_PER_PAGE,
      pageItemEndIndex: number = (this.page + 1) * this.ITEMS_PER_PAGE,
      resultStartIndex: number = pageItemStartIndex,
      resultEndIndex: number = pageItemEndIndex;

    this.shownResultsList =
      !!this.resultList.length && resultStartIndex >= 0 && resultStartIndex < resultEndIndex
        ? this.resultList.slice(resultStartIndex, resultEndIndex)
        : [];
    this.setFocusToSearchBar();
  }

  useOther(): void {
    if (this.searchString) {
      this.searchString = this.searchString.trim();
    } else {
      this.searchString = '';
    }

    const otherValue = this.searchString.substring(0, this.maxChars);

    let result: any = {
      // code: this.otherCode,
      other: true,
    };
    for (let searchField of this.searchFieldList) {
      if (!result.hasOwnProperty(searchField)) {
        result[searchField] = otherValue;
      }
    }
    this.viewCtrl.dismiss({ result: result });
  }

  // on-change callback for SearchBar input field
  clearInput(): void {
    if (!this.searchString) {
      this.resultList = this.searchList;
    } else {
      this.updateSearchStringPresence();
    }
    this.refreshPaginatedLists();
  }

  updateSearchStringPresence(): void {
    this.wasSearchStringPresent = !this.searchString ? false : true;
  }

  close(): void {
    this.viewCtrl.dismiss();
  }

  setFocusToSearchBar(): void {
    setTimeout(() => {
      this.searchbar.setFocus();
    }, 50);
  }
}
