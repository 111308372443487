<div tabindex="0" role="dialog" aria-label="#title">
  <ion-header>
    <ion-toolbar color="light">
      <ion-title>
        <h1 class="font-default margin-0" id="title">Disclaimer</h1>
      </ion-title>
      <ion-buttons slot="primary">
        <ion-button color="dark" (click)="close()" icon-only>
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <div class="padding-l">
    <p>The GPE generated by this calculator is provided as a guide only. It does not guarantee any offer to study at the University.</p>

    <p>
      Your final GPE will be calculated by University admissions staff. It will be based on the information provided on your academic
      transcript. Your academic transcript should contain your grades and the grading scale, both of which will be required by the
      University.
    </p>
    <p>
      The GPE calculator does not guarantee recognition of prior study, nor the equivalency of any prior study to University of Auckland
      qualifications. Recognition and equivalency of prior study will be evaluated by University admissions staff.
    </p>
    <p>All applicants must also meet any additional admission, programme and English language requirements to be eligible for entry.</p>
  </div>

  <div class="display-flex flex-end">
    <ion-button fill="clear" color="primary" (click)="close()"> Close </ion-button>
  </div>
</div>
