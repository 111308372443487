import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(private _modalController: ModalController) {}

  async openModal() {
    const modal = await this._modalController.create({
      component: DisclaimerComponent,
      cssClass: 'addGradeModal',
    });
    await modal.present();
  }
}
