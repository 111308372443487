<p #title [innerHtml]="titleText" tabindex="0"></p>

<uoa-steps-bar steps="2" [currentStep]="currentStep" (stepClicked)="gotoStep($event.detail)" *ngIf="mobile"></uoa-steps-bar>

<ion-grid id="ion-grid">
  <ion-row id="ion-row" class="border-light-2 background-light-1">
    <ion-col [size]="mobile? 4:3" class="padding-s d-flex flex-center" id="grade-text">Grade</ion-col>
    <ion-col [size]="mobile ? 6 : 4" class="d-flex padding-l flex-center text-center" *ngIf=" currentStep !==2" id="number-achieved"
      >Number of times achieved</ion-col
    >
    <ion-col [size]="mobile ? 6 : 5" class="d-flex padding-l flex-center" *ngIf="!mobile || currentStep===2" id="percentage-range">
      <span class="text-center">Percentage range (%)</span>
      <ion-button id="sort-grades" color="primary" aria-label="sort % range" fill="clear" (click)="sortGrades()">
        <ion-icon slot="start" aria-hidden="true" class="cursor-pointer" name="arrow-up" id="arrow-up-icon"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
  <ng-container *ngFor="let grade of gradeList">
    <app-grade
      [grade]="grade"
      [mobile]="mobile"
      [currentStep]="currentStep"
      [gradeType]="gradeType"
      [gradeList]="gradeList"
      [validGrades]="false"
      (deleteGrade)="deleteRow($event)"
    ></app-grade>
  </ng-container>
  <ion-row class="border-left-light-2 border-bottom-light-2 border-right-light-2" id="add-grade-row">
    <ion-col size="12" id="add-grade-col">
      <div class="padding-l">
        <ion-button
          id="add-grade-button"
          display="block"
          expand="full"
          color="tertiary"
          class="border-dashed d-flex flex-center margin-l"
          fill="clear"
          (click)="addGrade()"
        >
          <ion-icon name="add-circle-sharp" id="add-circle-sharp-icon"></ion-icon>
          Add Grade
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

<!-- <div class="border-full"></div> -->

<div class="d-flex flex-center margin-top-4xl" *ngIf="mobile && currentStep!==2 else institution">
  <ion-button (click)="gotoStep(2)" shape="round" size="large" class="btn-min-width" id="next-button">Next</ion-button>
</div>
<ng-template #institution id="institution-template">
  <app-institutions-list
    *ngIf="gradeList.length >= 3"
    [selectedInstitution]="previouslySelectedInstitution"
    (knownInstitute)="getInstitution($event)"
    (instituteSelected)="markInstituteSelected($event)"
    [doctoral]="knownInstitute"
  ></app-institutions-list>

  <ng-template [ngIf]="institutionSelected">
    <div id="select-grade">
      <div class="margin-top-3xl" id="select-grade-required-to-pass">
        Select the grade required to <strong>pass</strong> at your tertiary institution.
      </div>
      <div class="d-flex margin-left-40percent">
        <div class="input-container low-grade">
          <app-dropdown-field
            id="pass-grade-dropdown"
            [gradeList]="gradeList"
            [selectedGrade]="minPass?.name"
            (selectedValue)="updateValue($event, 'minPass')"
            [validGrades]="validGrades"
            [dropdownTitle]="'Select pass grade'"
          ></app-dropdown-field>
        </div>
      </div>
      <div class="range-bg">
        <div class="margin-left-40percent"></div>
      </div>
      <div class="margin-left-40percent" id="pass-text">Pass</div>
      <div *ngIf="!validGrades && !validMinPass()" class="color-error margin-top-s d-flex">
        <span class="d-flex align-center padding-right-xs font-l">
          <ion-icon aria-hidden="true" name="alert-circle-sharp" id="icon"></ion-icon>
        </span>
        <span id="error-message">Please select a grade required to Pass.</span>
      </div>
    </div>
    <div class="border-full margin-top-3xl"></div>
    <div class="d-flex margin-top-3xl flex-space-between" id="question">
      Would you like to answer two more questions for a more accurate calculation?
      <span><ion-toggle [(ngModel)]="answerMore" [checked]="answerMore" (ionChange)="answerMoreQuestions()"></ion-toggle> </span>
    </div>
    <div *ngIf="answerMore">
      <div>
        <div class="margin-top-l margin-bottom-s d-flex flex-space-between">
          <div id="lowest-b">Select the <strong>lowest B grade</strong> at your tertiary institution.</div>
          <uoa-help class="font-s" modal-title="What is a low B?" id="low-b-help">
            <p>On some grading systems this might be a B- or a B.</p>
            For example, in a standard U.S grading system of 0-4, a 2.7 GPA is equal to a low B.
          </uoa-help>
        </div>

        <div class="d-flex margin-left-40percent padding-left-2xl">
          <div class="input-container">
            <app-dropdown-field
              id="b-input"
              [gradeList]="gradeList"
              [selectedGrade]="minB?.name"
              (selectedValue)="updateValue($event, 'minB')"
              [dropdownTitle]="'Select lowest B grade'"
            ></app-dropdown-field>
          </div>
        </div>
        <div class="range-bg"><div class="margin-left-40percent"></div></div>
        <span class="d-flex margin-left-40percent padding-left-4xl" id="lowest-b-text">Lowest B </span>

        <div *ngIf="!validGrades && !validMinB()" class="color-error margin-top-s d-flex">
          <span class="d-flex align-center padding-right-xs font-l" id="s">
            <ion-icon aria-hidden="true" name="alert-circle-sharp" id="icon-b"></ion-icon>
          </span>
          <span id="instruction-b"> Please select a value higher than {{ minPass != null ? minPass.name : 'min pass grade' }}.</span>
        </div>
      </div>

      <div>
        <div class="margin-top-3xl margin-bottom-s d-flex flex-space-between">
          <div id="lowest-a">Select the <strong>lowest A grade</strong> at your tertiary institution.</div>
          <uoa-help class="font-s" modal-title="What is a low A?" id="low-a-help">
            <p>On some grading systems this might be an A- or an A.</p>
            For example, in a standard U.S grading system of 0-4, a 3.7 GPA is equal to a low A .
          </uoa-help>
        </div>
        <div class="d-flex flex-end margin-left-40percent padding-right-2xl">
          <div class="input-container">
            <app-dropdown-field
              id="a-input"
              [gradeList]="gradeList"
              [selectedGrade]="minA?.name"
              (selectedValue)="updateValue($event, 'minA')"
              [dropdownTitle]="'Select lowest A grade'"
            ></app-dropdown-field>
          </div>
        </div>
        <div class="range-bg"><div class="margin-left-40percent"></div></div>
        <div class="d-flex flex-end padding-right-4xl"><span class="padding-right-2xl" id="lowest-a-text">Lowest A</span></div>

        <div *ngIf="!validGrades && !validMinA()" class="color-error margin-top-s d-flex">
          <span class="d-flex align-center padding-right-xs font-l">
            <ion-icon name="alert-circle-sharp" id="icon-a"></ion-icon>
          </span>
          <span id="instruction-a"> Please select a value higher than {{ minB ? minB.name : 'min pass grade' }}.</span>
        </div>
      </div>
    </div>
    <div class="d-flex flex-center margin-top-4xl">
      <ion-button (click)="validateGpa()" shape="round" size="large" class="btn-min-width" id="calculate-non-numeric-button"
        >Calculate GPE</ion-button
      >
    </div>
  </ng-template>
</ng-template>
