import { Injectable } from '@angular/core';
import { GPA } from 'src/app/domain';

@Injectable({
  providedIn: 'root',
})
export class GpaValidationService {
  validExGpa(gpa: GPA, min: number, max: number): boolean {
    if (
      gpa.exgpa == null ||
      gpa.exgpa < min ||
      gpa.exgpa > max ||
      (!gpa.reverseGrades && gpa.exgpa > gpa.maxPass) ||
      (gpa.reverseGrades && gpa.exgpa < gpa.maxPass)
    ) {
      return false;
    }
    return true;
  }

  validMaxPass(gpa: GPA, min: number, max: number): boolean {
    if (gpa.maxPass == null || min > gpa.maxPass || gpa.maxPass > max) {
      return false;
    }
    return true;
  }

  validMinPass(gpa: GPA, min: number, max: number): boolean {
    if (gpa.minPass == null || min > gpa.minPass || gpa.minPass > max || (gpa.minPass === gpa.maxPass && gpa.maxPass != null)) {
      return false;
    }
    return true;
  }

  validMinB(gpa: GPA, min: number, max: number): boolean {
    if (
      gpa.minB != null &&
      (gpa.minB <= min ||
        gpa.minB >= max ||
        (!gpa.reverseGrades && (gpa.minB <= gpa.minPass || gpa.minB >= gpa.maxPass)) ||
        (gpa.reverseGrades && (gpa.minB >= gpa.minPass || gpa.minB <= gpa.maxPass)))
    ) {
      return false;
    }
    return true;
  }

  validMinA(gpa: GPA, min: number, max: number): boolean {
    if (
      gpa.minA != null &&
      (gpa.minA <= min ||
        gpa.minA >= max ||
        (!gpa.reverseGrades && (gpa.minA <= gpa.minPass || gpa.minA >= gpa.maxPass)) ||
        (gpa.reverseGrades && (gpa.minA >= gpa.minPass || gpa.minA <= gpa.maxPass)) ||
        (gpa.minB && ((!gpa.reverseGrades && gpa.minA <= gpa.minB) || (gpa.reverseGrades && gpa.minA >= gpa.minB))))
    ) {
      return false;
    }
    return true;
  }

  validateGPA(gpa: GPA, min: number, max: number): boolean {
    if (
      !this.validExGpa(gpa, min, max) ||
      !this.validMaxPass(gpa, min, max) ||
      !this.validMinPass(gpa, min, max) ||
      !this.validMinB(gpa, min, max) ||
      !this.validMinA(gpa, min, max)
    ) {
      return false;
    }
    return true;
  }
}
