import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { IonicModule } from '@ionic/angular';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';

const COMPONENT = [FooterComponent, DisclaimerComponent];

@NgModule({
  declarations: [COMPONENT],
  exports: [COMPONENT],
  imports: [CommonModule, IonicModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FooterModule {}
