import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GPA, GradeDetails, GradeType, RangeValidation } from 'src/app/domain';

import { CalculatorConfig } from '../config';

@Injectable({
  providedIn: 'root',
})
export class NonNumericCalculatorService {
  _rangesList: RangeValidation;
  public rangesList = new BehaviorSubject({});
  rangesList$: Observable<any>;

  constructor() {
    this.rangesList$ = this.rangesList;
  }
  addRangesValidationToStream() {
    this.rangesList.next(this._rangesList);
  }
  getGradesList(type: GradeType): GradeDetails[] {
    const index = Object.values(GradeType).indexOf(type);
    const key = Object.keys(GradeType)[index];

    let list = this.createDefaultGradeList(CalculatorConfig.GRADE_DETAILS[key]);
    this.resetRangeList(list);
    this.addRangesValidationToStream();
    return list;
  }

  updateGradesList(gradeDetails: GradeDetails[]): void {
    this.resetRangeList(gradeDetails);
    this.addRangesValidationToStream();
  }

  createDefaultGradeList(gradeDetails: GradeDetails[]): GradeDetails[] {
    return gradeDetails.map((grade) => new GradeDetails(grade.name, 0, grade.rangeTo, grade.rangeFrom));
  }

  resetRangeList(gradeDetails: GradeDetails[]) {
    this._rangesList = Object.assign({});
    return gradeDetails.map((grade) => {
      return (this._rangesList[grade.name] = new RangeValidation(grade.name));
    });
  }

  getNumericGrades(gradeDetailsList: GradeDetails[], minPassName: string, minAName: string, minBName: string): GPA {
    let exgpa = this.calculateExgpa(gradeDetailsList);
    let minPass = this.getMinimumGrade(gradeDetailsList, minPassName);
    let maxPass = this.getMaxPassGrade(gradeDetailsList);

    let minA = this.getMinimumGrade(gradeDetailsList, minAName);
    let minB = this.getMinimumGrade(gradeDetailsList, minBName);
    return new GPA(exgpa, maxPass, minPass, minB, minA);
  }

  getMinimumGrade(gradeDetailsList: GradeDetails[], gradeName: string): number {
    if (!gradeName) {
      return 0;
    }
    let grade = gradeDetailsList.find((grade) => grade.name === gradeName);
    return grade ? grade.rangeFrom : -1;
  }

  getMaxPassGrade(gradeDetailsList: GradeDetails[]): number {
    return Math.max.apply(
      Math,
      gradeDetailsList.map((grade) => grade.rangeTo)
    );
  }

  calculateExgpa(gradeDetailsList: GradeDetails[]): number {
    let gradeMidpoint = 0;
    let totalGradeCount = 0;
    gradeDetailsList.forEach((grade) => {
      if (grade.name != null) {
        totalGradeCount += grade.timesAchieved;
        gradeMidpoint += grade.timesAchieved * this.avg(grade.rangeFrom, grade.rangeTo);
      }
    });
    return gradeMidpoint / totalGradeCount;
  }

  avg(from: number, to: number): number {
    return (from + to) / 2.0;
  }
}
