import { Injectable } from '@angular/core';
import { GradeDetails } from 'src/app/domain';
import { NonNumericCalculatorService } from './non-numeric-calculator.service';

@Injectable({
  providedIn: 'root',
})
export class NonNumericValidationService {
  updatedRangeCode: string;
  lastRangeErrorCode: string;

  constructor(public NonNumericService: NonNumericCalculatorService) {}

  ValidGradeDetails(gradeList): boolean {
    let isValid: boolean = true;
    gradeList.forEach((grade) => {
      if (!grade.isValid()) {
        isValid = false;
      }
    });
    isValid = isValid ? this.validateRanges(gradeList) : isValid;
    return isValid;
  }

  validateRanges(gradeList: GradeDetails[]) {
    let valid = true;
    this.NonNumericService.resetRangeList(gradeList);
    gradeList.forEach((grade) => {
      let isValid = this._validateRange(gradeList, grade);
      if (!isValid) {
        valid = false;
      }
    });
    this.NonNumericService.addRangesValidationToStream();
    return valid;
  }
  getSelectedGrade(list: GradeDetails[], name: string): GradeDetails {
    return list.find((li) => li.name === name);
  }
  private _validateRange(gradeList: GradeDetails[], selectedGrade: GradeDetails) {
    let valid = true;
    let selectedObj = this.NonNumericService._rangesList[selectedGrade.name];
    gradeList.forEach((grade) => {
      if (grade.name !== selectedGrade.name) {
        let obj = this.NonNumericService._rangesList[grade.name];
        let duplicate = this._duplicateRange(grade, selectedGrade, obj, selectedObj);
        if (!duplicate) {
          this._overLappingRange(grade, selectedGrade, obj, selectedObj);
        }
        if (!obj.valid) {
          valid = false;
          this.lastRangeErrorCode = grade.name;
        }
      }
    });
    return valid;
  }

  private _duplicateRange(grade, selectedGrade, validObj, selectedObj) {
    if (grade.rangeFrom === selectedGrade.rangeFrom && grade.rangeTo === selectedGrade.rangeTo) {
      selectedObj.duplicate = true;
      selectedObj.valid = false;
      validObj.duplicate = true;
      validObj.valid = false;
    }
    return selectedObj.duplicate;
  }

  private _overLappingRange(grade, selectedGrade, validObj, selectedObj) {
    if (
      (selectedGrade.rangeFrom >= grade.rangeFrom && selectedGrade.rangeFrom <= grade.rangeTo) ||
      (selectedGrade.rangeTo >= grade.rangeFrom && selectedGrade.rangeTo <= grade.rangeTo)
    ) {
      selectedObj.overlap = true;
      selectedObj.valid = false;
      validObj.overlap = true;
      validObj.valid = false;
    }
  }
}
