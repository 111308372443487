import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { NumericGradesComponent } from './numeric-grades.component';
import { InputFieldComponent } from './input-field/input-field.component';
import { InstitutionsListModule } from '../institutions-list';

const COMPONENTS = [NumericGradesComponent, InputFieldComponent];

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, FormsModule, InstitutionsListModule],
  declarations: COMPONENTS,
  exports: [NumericGradesComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NumericGradesModule {}
