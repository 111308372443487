<ion-app>
  <ion-content>
    <div class="content-wrapper">
      <app-header></app-header>
      <ng-template [ngIf]="!calculateSummary">
        <div class="background-light-1">
          <div class="inner-content inner-content-padding-bottom padding-m">
            <h1 class="padding-top-5xl margin-top-0 color-primary not-bold font-xl">Grade Point Equivalent (GPE) calculator</h1>
            <p class="d-flex flex-column padding-top-s">
              <strong>Find out if your previous studies meet our minimum requirements.</strong>
              Our GPE calculator converts your grades from another tertiary institute to a GPE to see where it sits on our grading scale.
            </p>
          </div>
        </div>
        <div class="inner-content margin-top-4xl">
          <h2 class="color-primary not-bold">Your grade details</h2>
          <app-gpa-type (gpaType)="setType($event)"></app-gpa-type>
          <div class="margin-bottom-4xl margin-top-l" *ngIf="gpaType && !calculateSummary">
            <app-numeric-gpa
              *ngIf="gpaType === gradeTypes.NUMERIC || gpaType === gradeTypes.PERCENT"
              [numericGpa]="gpaType === gradeTypes.NUMERIC"
              [editButtonPressed]="editButtonPressed"
              (showSummary)="showSummary($event)"
              (gpaGrades)="selectedGrades($event)"
            ></app-numeric-gpa>
            <app-non-numeric
              *ngIf="gpaType !== gradeTypes.NUMERIC && gpaType !== gradeTypes.PERCENT"
              [gradeType]="gpaType"
              [editButtonPressed]="editButtonPressed"
              (showSummary)="showSummary($event)"
              (gpaGrades)="selectedGrades($event)"
            ></app-non-numeric>
          </div>
        </div>
      </ng-template>
      <app-summary *ngIf="calculateSummary" (reset)="resetTypeField()" (editButtonPressed)="editGrades()"></app-summary>
      <app-footer></app-footer>
    </div>
  </ion-content>
</ion-app>
