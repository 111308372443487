<ion-row class="border-light-2">
  <ion-col [size]="mobile ? 4 : 3" class="padding-s d-flex align-center text-center" [class.flex-center]="gradeType !== type.ABC">
    <span
      id="grade-name"
      [class.padding-left]="type.ABC === gradeType"
      [class.margin-left-3xl]="type.ABC === gradeType && !mobile"
      [class.margin-left-m]="type.ABC === gradeType && mobile"
    >
      {{ grade.name | titlecase }}
    </span>
  </ion-col>
  <ion-col [size]="mobile ? 6 : 4" *ngIf="currentStep !== 2">
    <app-grade-times-received
      class="d-flex padding-l flex-center flex-column"
      [grade]="grade"
      [valid]="validGrades"
      (selectedTimesAchieved)="selectedTimesAchieved($event)"
    ></app-grade-times-received>
  </ion-col>
  <ion-col [size]="mobile ? 6 : 4" class="padding-l text-center" *ngIf="!mobile || currentStep === 2">
    <span
      ><app-grade-range
        class="d-flex align-center flex-column"
        [grade]="grade"
        [gradeList]="gradeList"
        [valid]="validGrades"
      ></app-grade-range>
    </span>
  </ion-col>
  <ion-col [size]="mobile ? 2 : 1" class="d-flex align-center">
    <span class="d-flex flex-center" *ngIf="(!mobile || currentStep === 2) && gradeList.length > 3">
      <ion-button fill="clear" (click)="deleteRow()">
        <ion-icon class="font-l" color="tertiary" name="trash" slot="icon-only"></ion-icon>
      </ion-button>
    </span>
  </ion-col>
</ion-row>
