import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { GPA } from 'src/app/domain';

@Injectable({
  providedIn: 'root',
})
export class NumericCalculatorService {
  private MIN_GPE = 0;
  private MAX_GPE = 9;
  finalNumericGpa: GPA;

  constructor(private _toastCtrl: ToastController) {}
  public calculate(exgpa: number, minPass: number, maxPass: number, minA: number, minB: number, knownInstitute: boolean): number {
    let gpe = -1;
    if (minA === 0 && minB === 0) {
      // CASE 6
      if ((maxPass > minPass && exgpa >= minPass) || (maxPass < minPass && exgpa <= minPass)) {
        gpe = 1 + ((exgpa - minPass) * 8) / (maxPass - minPass);
      }
    } else if (minPass < maxPass) {
      if (exgpa <= maxPass && exgpa >= minA) {
        // CASE 1
        gpe = 7 + ((exgpa - minA) * 2) / (maxPass - minA);
      } else if (exgpa < minA && exgpa >= minB) {
        // CASE 2
        gpe = 4 + ((exgpa - minB) * 3) / (minA - minB);
      } else if (exgpa >= minPass && exgpa < minB) {
        // CASE 3
        gpe = 1 + ((exgpa - minPass) * 3) / (minB - minPass);
      } else if (exgpa < minPass) {
        // CASE 4
        gpe = 0;
      } else if (exgpa > maxPass) {
        // CASE 5
        gpe = 9;
      }
    } else if (minPass > maxPass) {
      if (exgpa >= maxPass && exgpa <= minA) {
        // CASE 1
        gpe = 7 + ((exgpa - minA) * 2) / (maxPass - minA);
      } else if (exgpa > minA && exgpa <= minB) {
        // CASE 2
        gpe = 4 + ((exgpa - minB) * 3) / (minA - minB);
      } else if (exgpa <= minPass && exgpa > minB) {
        // CASE 3
        gpe = 1 + ((exgpa - minPass) * 3) / (minB - minPass);
      } else if (exgpa > minPass) {
        // CASE 4
        gpe = 0;
      } else if (exgpa < maxPass) {
        // CASE 5
        gpe = 9;
      }
    }
    gpe = this.checkGpe(gpe);
    if (!knownInstitute) {
      gpe = gpe - 1;
    }
    return this.checkGpe(gpe);
  }

  public checkGpe(gpe: number) {
    return gpe < this.MIN_GPE ? this.MIN_GPE : gpe > this.MAX_GPE ? this.MAX_GPE : this.round(gpe.toFixed(3), 2);
  }

  public round(value, precision) {
    let multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  async showErrorToast() {
    const toast = await this._toastCtrl.create({
      message: 'Please complete all required fields.',
      position: 'middle',
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });
    toast.present();
  }
}
