<div class="margin-top-l margin-bottom-l">
  <div id="recent-overall-grade">
    What is the <strong>overall {{ gpaType }} </strong> for your most recent tertiary qualification?
  </div>
  <div class="input-container">
    <app-input-field
      id="overall-grade-input"
      [class.error]="!valid && !validExgpa()"
      [gpa]="gpa.exgpa"
      [numeric]="numericGpa"
      (selectedValue)="updateValue($event, 'exgpa')"
      [placeholder]="placeholders.EX_GPA"
      [textCenter]="false"
    ></app-input-field>
  </div>
  <div *ngIf="!valid && !validExgpa()" class="color-error margin-top-s d-flex">
    <span class="d-flex align-center padding-right-xs font-l">
      <ion-icon name="alert-circle-sharp"></ion-icon>
    </span>
    <span>Please enter a value between {{ gpa.minPass != null ? gpa.minPass : min }} - {{ gpa.maxPass != null ? gpa.maxPass : max }}.</span>
  </div>
</div>
<div class="border-full"></div>
<div class="margin-top-3xl margin-bottom-l">
  <app-institutions-list
    [selectedInstitution]="previouslySelectedInstitution"
    (knownInstitute)="getInstitution($event)"
    (instituteSelected)="markInstituteSelected($event)"
    [doctoral]="gpa.knownInstitute"
  ></app-institutions-list>
</div>
<ng-template [ngIf]="institutionSelected">
  <div class="margin-top-4xl">
    <div id="max-grade-achievable-q" class="margin-top-3xl margin-bottom-s">
      What is the <strong>maximum {{ gpaType }} </strong> you can achieve at your tertiary institution?
    </div>
    <div class="d-flex flex-end">
      <div class="input-container" [class.input-triangle-error]="!valid && !validMaxPass()">
        <app-input-field
          id="max-grade-input"
          [gpa]="gpa.maxPass"
          [numeric]="numericGpa"
          (selectedValue)="updateValue($event, 'maxPass')"
          [placeholder]="placeholders.MAX_PASS"
        ></app-input-field>
      </div>
    </div>
  </div>

  <div class="range-bg"><div class="margin-left-3xl" [class.margin-left-40percent]="!numericGpa"></div></div>

  <span class="d-flex flex-end" id="max-indicator">Maximum</span>
  <div *ngIf="!valid && !validMaxPass()" class="color-error margin-top-s d-flex">
    <span class="d-flex align-center padding-right-xs font-l">
      <ion-icon name="alert-circle-sharp"></ion-icon>
    </span>
    <span>Please enter a value between {{ min }} - {{ max }}.</span>
  </div>
  <div>
    <div id="required-grade-for-pass-q" class="margin-top-3xl">
      What is the {{ gpaType }} is required to <strong>pass</strong> at your tertiary institution?
    </div>
    <div class="d-flex margin-left-3xl" [class.margin-left-40percent]="!numericGpa">
      <div class="input-container low-grade" [class.input-triangle-error]="!valid && !validMinPass()">
        <app-input-field
          id="required-grade-for-pass-input"
          [gpa]="gpa.minPass"
          [numeric]="numericGpa"
          (selectedValue)="updateValue($event, 'minPass')"
          [placeholder]="placeholders.MIN_PASS"
        ></app-input-field>
      </div>
    </div>
    <div class="range-bg">
      <div class="margin-left-3xl" [class.margin-left-40percent]="!numericGpa"></div>
    </div>
    <div id="pass-indicator" class="margin-left-3xl" [class.margin-left-40percent]="!numericGpa">Pass</div>
    <div *ngIf="!valid && !validMinPass()" class="color-error margin-top-s d-flex">
      <span class="d-flex align-center padding-right-xs font-l">
        <ion-icon name="alert-circle-sharp"></ion-icon>
      </span>
      <span *ngIf="gpa.minPass == null || gpa.minPass < min || gpa.minPass > max">Please enter a value between {{ min }} - {{ max }}.</span>
      <span *ngIf="gpa.maxPass != null && gpa.minPass === gpa.maxPass"> Pass value cannot be the same as maximum value.</span>
    </div>
  </div>
  <div class="border-full margin-top-3xl"></div>
  <div id="answer-more-questions" class="d-flex margin-top-3xl flex-space-between">
    Would you like to answer two more questions for a more accurate calculation?
    <span><ion-toggle [(ngModel)]="answerMore" [checked]="answerMore" (ionChange)="answerMoreQuestions()"></ion-toggle> </span>
  </div>
  <div *ngIf="answerMore">
    <div>
      <div class="margin-top-l margin-bottom-s d-flex flex-space-between">
        <div id="low-B-question">What is the {{ gpaType }} for the <strong>lowest B grade</strong> at your tertiary institution?</div>
        <uoa-help id="low-B-help" class="font-s" modal-title="What is a low B?">
          <p>On some grading systems this might be a B- or a B.</p>
          For example, in a standard U.S grading system of 0-4, a 2.7 GPA is equal to a low B.
        </uoa-help>
      </div>

      <div class="d-flex margin-left-40percent padding-left-2xl">
        <div class="input-container" [class.input-triangle-error]="!valid && !validMinB()">
          <app-input-field
            id="low-B-input"
            [gpa]="gpa.minB"
            [numeric]="numericGpa"
            (selectedValue)="updateValue($event, 'minB')"
            [placeholder]="placeholders.MIN_B"
          ></app-input-field>
        </div>
      </div>
      <div class="range-bg"><div class="margin-left-40percent"></div></div>
      <span id="b-minus-and-b-indicator" class="d-flex margin-left-40percent padding-left-4xl"
        >&nbsp; <span class="padding-left-2xl">B- / B</span>
      </span>

      <div *ngIf="!valid && !validMinB()" class="color-error margin-top-s d-flex">
        <span class="d-flex align-center padding-right-xs font-l">
          <ion-icon name="alert-circle-sharp"></ion-icon>
        </span>
        <span>
          Low B value should be between {{ gpa.minPass != null ? gpa.minPass : min }} - {{ gpa.maxPass != null ? gpa.maxPass : max }}.</span
        >
      </div>
    </div>

    <div>
      <div class="margin-top-3xl margin-bottom-s d-flex flex-space-between">
        <div id="low-A-question">What is the {{ gpaType }} for the <strong>lowest A grade</strong> at your tertiary institution?</div>
        <uoa-help id="low-A-help" class="font-s" modal-title="What is a low A?">
          <p>On some grading systems this might be an A- or an A.</p>
          For example, in a standard U.S grading system of 0-4, a 3.7 GPA is equal to a low A .
        </uoa-help>
      </div>
      <div class="d-flex flex-end margin-left-40percent padding-right-2xl">
        <div class="input-container" [class.input-triangle-error]="!valid && !validMinA()">
          <app-input-field
            id="low-A-input"
            [gpa]="gpa.minA"
            [numeric]="numericGpa"
            (selectedValue)="updateValue($event, 'minA')"
            [placeholder]="placeholders.MIN_A"
          ></app-input-field>
        </div>
      </div>
      <div class="range-bg"><div class="margin-left-40percent"></div></div>
      <div id="a-minus-and-a-indicator" class="d-flex flex-end padding-right-4xl"><span class="padding-right-2xl">A- / A</span></div>

      <div *ngIf="!valid && !validMinA()" class="color-error margin-top-s d-flex">
        <span class="d-flex align-center padding-right-xs font-l">
          <ion-icon name="alert-circle-sharp"></ion-icon>
        </span>
        <span>
          Low A value should be between {{ gpa.minB && validMinB() ? gpa.minB : gpa.minPass != null ? gpa.minPass : min }} -
          {{ gpa.maxPass != null ? gpa.maxPass : max }}.</span
        >
      </div>
    </div>
  </div>
  <div class="d-flex flex-center margin-top-4xl">
    <ion-button id="calculate-numeric-button" (click)="validateGPA()" shape="round" size="large" class="btn-min-width"
      >Calculate GPE</ion-button
    >
  </div>
</ng-template>
