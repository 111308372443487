export class CalculatorConfig {
  public static readonly NUMERIC_GPA = {
    MIN: 0,
    MAX: 25,
    PLACEHOLDERS: {
      EX_GPA: 'Eg: 3.7',
      MAX_PASS: 'Eg: 4',
      MIN_PASS: 'Eg: 2',
      MIN_A: 'Eg: 3.7',
      MIN_B: 'Eg: 2.5',
    },
  };

  public static readonly PERCENT_GPA = {
    MIN: 0,
    MAX: 120,
    PLACEHOLDERS: {
      EX_GPA: 'Eg: 70 %',
      MAX_PASS: 'Eg: 100 %',
      MIN_PASS: 'Eg: 50 %',
      MIN_A: 'Eg: 80 %',
      MIN_B: 'Eg: 60 %',
    },
  };

  public static readonly GRADE_DETAILS = {
    ABC: [
      { name: 'A+', rangeFrom: 90, rangeTo: 100 },
      { name: 'A', rangeFrom: 80, rangeTo: 89 },
      { name: 'B+', rangeFrom: 75, rangeTo: 79 },
      { name: 'B', rangeFrom: 65, rangeTo: 74 },
      { name: 'C+', rangeFrom: 60, rangeTo: 64 },
      { name: 'C', rangeFrom: 50, rangeTo: 59 },
      { name: 'D', rangeFrom: 40, rangeTo: 49 },
      { name: 'F', rangeFrom: 0, rangeTo: 39 },
    ],
    HONOURS: [
      { name: 'First', rangeFrom: 80, rangeTo: 100 },
      { name: 'Upper Second (Division 1)', rangeFrom: 70, rangeTo: 79 },
      { name: 'Lower Second (Division 2)', rangeFrom: 60, rangeTo: 69 },
      { name: 'Third', rangeFrom: 50, rangeTo: 59 },
      { name: 'Fail', rangeFrom: 0, rangeTo: 49 },
    ],
    DISTINCTION: [
      { name: 'High Distinction', rangeFrom: 85, rangeTo: 100 },
      { name: 'Distinction', rangeFrom: 75, rangeTo: 84 },
      { name: 'Merit', rangeFrom: 65, rangeTo: 74 },
      { name: 'Pass', rangeFrom: 50, rangeTo: 64 },
      { name: 'Fail', rangeFrom: 0, rangeTo: 49 },
    ],
    EXCELLENCE: [
      { name: 'Excellent', rangeFrom: 90, rangeTo: 100 },
      { name: 'Very Good', rangeFrom: 80, rangeTo: 89 },
      { name: 'Good', rangeFrom: 70, rangeTo: 79 },
      { name: 'Pass', rangeFrom: 60, rangeTo: 69 },
      { name: 'Weak', rangeFrom: 50, rangeTo: 59 },
      { name: 'Fail', rangeFrom: 0, rangeTo: 49 },
    ],

    OTHER: [],
  };
}
